import React from "react"
import styled from "styled-components"

import Forger1Image from "./images/set_and_forget_one.png"
import Forger2Image from "./images/set_and_forget_two.png"
import Forger3Image from "./images/set_and_forget_three.png"

import { Row, Row2, Col, Img } from "./style"
import { ContainerPage, WrapperPage } from "src/componentsV2/ui/layout"
import { Title, SubTitle } from "src/componentsV2/sections/Text"

const AutoPolicyUpdatesContainer = styled(ContainerPage)`
  padding-top: 60px;
  padding-bottom: 75px;
  @media (min-width: 768px) {
    padding-top: 105px;
    padding-bottom: 70px;
  }
`

const SetAndForgetMobile = () => {
  return (
    <div className="block md:hidden">
      <Row className="items-center">
        <Col className="text-center">
          <Title>
            <div style={{ textAlign: "center" }}>Set and forget</div>
          </Title>
        </Col>
        <Col className="text-center">
          <Img src={Forger1Image} width="600" height="412" alt="handbook apu" />
          <SubTitle>
            <div className="font-bold md:pt-10 pt-6 text-center text-black">
              Monitoring compliance changes
            </div>
          </SubTitle>
          <SubTitle>
            <div style={{ textAlign: "center" }}>
              AirMason's AI automatically keeps track of policy changes for any
              relevant state(s) and federal policies you have within your
              handbook.
            </div>
          </SubTitle>
        </Col>
        <Col className="text-center">
          <Img src={Forger2Image} width="600" height="412" alt="handbook apu" />
          <SubTitle>
            <div className="font-bold md:pt-10 pt-6 text-center text-black">
              AI driven suggestions. Curated by HR experts
            </div>
          </SubTitle>
          <SubTitle>
            <div style={{ textAlign: "center" }}>
              Each suggested change is reviewed carefully by an HR expert to see
              if it's relevant to your specific use case.
            </div>
          </SubTitle>
          <SubTitle>
            <div style={{ textAlign: "center" }}>
              Then, whenever a change is required, you will receive an email
              with the suggested changes, all tailored to your specific
              handbook(s).
            </div>
          </SubTitle>
        </Col>
        <Col className="text-center">
          <Img src={Forger3Image} width="600" height="412" alt="handbook apu" />

          <SubTitle>
            <div className="font-bold md:pt-10 pt-6 text-center text-black">
              Approve & publish the changes
            </div>
          </SubTitle>
          <SubTitle>
            <div style={{ textAlign: "center" }}>
              You can then approve & publish the changes, instantly updating
              your handbook.
            </div>
          </SubTitle>
          <SubTitle>
            <div style={{ textAlign: "center" }}>
              And then continue on the day; focus your time on the other 99
              projects on your plate.
            </div>
          </SubTitle>
        </Col>
      </Row>
    </div>
  )
}

const SetAndForgetDesktop = () => {
  return (
    <div className="hidden md:block">
      <Row className="items-center">
        <Title style={{ marginBottom: "0px" }}>
          <div style={{ textAlign: "center" }}>Set and forget</div>
        </Title>
      </Row>
      <Row className="items-center" style={{ gap: 20 }}>
        <Col className="text-center">
          <Img src={Forger1Image} width="600" height="412" alt="handbook apu" />
        </Col>
        <Col className="text-center">
          <Img src={Forger2Image} width="600" height="412" alt="handbook apu" />
        </Col>
        <Col className="text-center">
          <Img src={Forger3Image} width="600" height="412" alt="handbook apu" />
        </Col>
      </Row>
      <Row2 className="items-start" style={{ gap: 20 }}>
        <Col className="text-center">
          <SubTitle>
            <div
              style={{
                textAlign: "center",
                color: "black",
                fontSize: "19px",
                paddingBottom: "18px",
                paddingTop: "20px",
              }}
              className="font-bold"
            >
              Monitoring compliance changes
            </div>
          </SubTitle>
          <SubTitle>
            <div
              style={{
                textAlign: "center",
                maxWidth: "348px",
                fontSize: "19px",
              }}
            >
              AirMason's AI automatically keeps track of policy changes for any
              relevant state(s) and federal policies you have within your
              handbook.
            </div>
          </SubTitle>
        </Col>
        <Col className="text-center">
          <SubTitle>
            <div
              style={{
                textAlign: "center",
                color: "black",
                fontSize: "19px",
                paddingBottom: "18px",
                paddingTop: "20px",
              }}
              className="font-bold"
            >
              AI driven suggestions. Curated by HR experts
            </div>
          </SubTitle>
          <SubTitle>
            <div
              style={{
                textAlign: "center",
                maxWidth: "332px",
                fontSize: "19px",
                paddingBottom: "30px",
              }}
            >
              Each suggested change is reviewed carefully by an HR expert to see
              if it's relevant to your specific use case.
            </div>
          </SubTitle>
          <SubTitle>
            <div
              style={{
                textAlign: "center",
                maxWidth: "332px",
                fontSize: "19px",
              }}
            >
              Then, whenever a change is required, you will receive an email
              with the suggested changes, all tailored to your specific
              handbook(s).
            </div>
          </SubTitle>
        </Col>
        <Col className="text-center">
          <SubTitle>
            <div
              style={{
                textAlign: "center",
                color: "black",
                fontSize: "19px",
                paddingBottom: "18px",
                paddingTop: "20px",
              }}
              className="font-bold"
            >
              Approve & publish the changes
            </div>
          </SubTitle>
          <SubTitle>
            <div
              style={{
                textAlign: "center",
                maxWidth: "302px",
                fontSize: "19px",
                paddingBottom: "30px",
              }}
            >
              You can then approve & publish the changes, instantly updating
              your handbook.
            </div>
          </SubTitle>
          <SubTitle>
            <div
              style={{
                textAlign: "center",
                maxWidth: "302px",
                fontSize: "19px",
              }}
            >
              And then continue on the day; focus your time on the other 99
              projects on your plate.
            </div>
          </SubTitle>
        </Col>
      </Row2>
    </div>
  )
}

const index = () => {
  return (
    <AutoPolicyUpdatesContainer data-loadable-component="auto-policy-updates-set-and-forget">
      <WrapperPage>
        <SetAndForgetMobile />
        <SetAndForgetDesktop />
      </WrapperPage>
    </AutoPolicyUpdatesContainer>
  )
}

export default index
